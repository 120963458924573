interface TextProps {
  children: React.ReactNode;
  center?: boolean;
  noMargin?: boolean;
  className?: string;
}

export const H1 = ({ children, center, noMargin, className }: TextProps) => {
  return (
    <h1
      className={`text-4xl  font-bold tracking-tight text-gray-200 sm:text-6xl ${
        center ? "text-center " : "text-left"
      } ${className || ""} ${noMargin ? "" : "mb-8 md:mb-12"}`}
    >
      {children}
    </h1>
  );
};

export const H2 = ({ children, center, noMargin, className }: TextProps) => {
  return (
    <h2
      className={`text-xl text-gray-300 sm:text-xl md:text-2xl xl:text-4xl ${
        center ? "text-center " : "text-left"
      } ${className || ""} ${noMargin ? "" : "mb-3 md:mb-6"}`}
    >
      {children}
    </h2>
  );
};

export const H3 = ({ children, center, noMargin, className }: TextProps) => {
  return (
    <h3
      className={`text-lg text-gray-300 sm:text-lg md:text-xl xl:text-2xl ${
        center ? "text-center " : "text-left"
      } ${className || ""} ${noMargin ? "" : "mb-3 md:mb-6"}`}
    >
      {children}
    </h3>
  );
};

export const P = ({ children, center, noMargin, className }: TextProps) => {
  return (
    <p
      className={`text-gray-300 sm:text-lg lg:text-xl ${
        center ? "text-center " : "text-left"
      } ${className || ""} ${noMargin ? "" : "mb-3 md:mb-6"}`}
    >
      {children}
    </p>
  );
};
